<template>
  <el-dialog
      class="login-dialog"
      title="Вход в аккаунт"
      :visible.sync="showDialog"
      :show-close="false"
      >
    <div @click="showDialog = false" class="el-dialog__close"></div>
    <span class="el-dialog__subtitle">Рады видеть вас снова в КТЖе!</span>
    <div class="mt-16">
      <el-form ref="login" @submit.prevent="submitForm('login')" :model="login" :rules="rules">
        <el-form-item prop="username" required>
          <el-input placeholder="Электронная почта" v-model="login.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" required>
          <el-input placeholder="Пароль" type="password" v-model="login.password"></el-input>
        </el-form-item>
        <el-form-item>
          <a class="link-forgot" href="/forgot-password">Забыли пароль?</a>
        </el-form-item>
        <el-form-item class="mt-87">
          <el-button @click="submitForm('login')" class="btn btn-black">ВОЙТИ</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "LoginDialog",
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      showDialog: this.value,
      login: {
        username: null,
        password: null
      },
      rules: {
        password: [
          { required: true, message: 'Введите пароль', trigger: ['change','blur'] },
          { min: 6, message: 'Пароль должен содержать минимум 6 символов', trigger: 'blur' }
        ],
        username: [
          { type: 'email', message: 'Это поле должно быть действующим email', trigger: ['blur', 'change'] },
          { required: true, message: 'Введите email', trigger: ['blur', 'change'] },
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$auth
              .login({
                data: this.login,
                rememberMe: true,
                url: 'login_check',
                redirect: this.$route.path
              })
              .then((res) => {
                if(res.status === 200) {
                  this.showDialog = false
                } else {
                  this.$toastr.e('Неверный логин или пароль')
                }
              });
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    showDialog(val) {
      this.showDialog = val
      this.$emit('input', val)
    },
    value(val) {
      this.showDialog = val
    }
  }
}
</script>

<style lang="scss">
.login-dialog {
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}
</style>