<template>
  <div class="header">
    <router-link to="/" class="pt-20 logo">
      <img alt="Uni logo" src="../assets/logo.svg">
    </router-link>
    <main-menu>
      <div class="mr-10 text-center">
        <div>
          <img width="130" alt="Kz logo" src="../assets/kz.svg">
        </div>
        <div style="color: #ae8900; font-size: 11px; font-weight: bold;">СЕТЕВОЙ УНИВЕРСИТЕТ</div>
      </div>
    </main-menu>
    <mobile-menu />
  </div>
</template>

<script>
import MainMenu from "./MainMenu.vue";
import MobileMenu from "./MobileMenu.vue";

export default {
  name: "TplHeader",
  components: {MobileMenu, MainMenu}
}
</script>

<style scoped>
</style>