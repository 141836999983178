<template>
  <div class="footer mt-40">
    <div class="wrapper">
      <div class="menu">
        <div class="md-hidden">
          <nav class="menu__item d-flex align-center flex-direction-row">
            <a class="mr-40 logo" href="/"><img alt="Uni logo" src="../assets/logo.svg"></a>
            <router-link to="/catalog">Каталог курсов</router-link>
          </nav>
        </div>
        <div class="md-visible">
          <a class="mr-40 logo" href="/"><img alt="Uni logo" src="../assets/logo.svg"></a>
        </div>
        <div class="d-flex align-items-center">
          <button @click="toTop" class="btn btn-white btn-up">
            <span>Наверх</span>
            <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.53033 0.803022C6.23744 0.510129 5.76256 0.510129 5.46967 0.803022L0.696699 5.57599C0.403805 5.86889 0.403805 6.34376 0.696699 6.63665C0.989592 6.92955 1.46447 6.92955 1.75736 6.63665L6 2.39401L10.2426 6.63665C10.5355 6.92955 11.0104 6.92955 11.3033 6.63665C11.5962 6.34376 11.5962 5.86889 11.3033 5.57599L6.53033 0.803022ZM6.75 14.6667L6.75 1.33335L5.25 1.33335L5.25 14.6667L6.75 14.6667Z" fill="#1F1F1F"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="md-hidden">
        <div class="footer__menu">
          <ul style="flex: 2">
            <li>Техническая, методическая поддержка</li>
            <li><a href="tel:+7 (495) 720-55-84">+7 (495) 720-55-84 <br/>(пн-сб с 9:00 до 18:00 по Москве)</a></li>
          </ul>
          <ul style="flex: 2">
            <li><a target="_blank" href="https://t.me/universariumchat">Чат поддержки</a></li>
            <li><a href="/#faq">Вопросы и ответы</a></li>
            <li><router-link to="/about">О проекте</router-link></li>
            <li><router-link to="/news">Новости</router-link></li>
          </ul>
          <ul style="flex: 3">
            <li class="d-flex justify-space-between">
              <a target="_blank" href="https://miigaik.ru/files/agreementpd.pdf">Политика конфиденциальности</a>
              <a target="_blank" href="https://vk.com/miigaik">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.489 0.00397656L10.218 0.000976562H13.782L14.512 0.00397656L15.426 0.0139766L15.859 0.0209766L16.277 0.0319766L16.68 0.0459766L17.068 0.0619766L17.442 0.0829766L17.802 0.107977L18.147 0.137977L18.48 0.170977C20.22 0.366977 21.413 0.786977 22.313 1.68698C23.213 2.58698 23.633 3.77898 23.829 5.51998L23.863 5.85298L23.892 6.19898L23.917 6.55898L23.937 6.93198L23.962 7.51998L23.974 7.92998L23.987 8.57398L23.996 9.48898L24 10.469L23.999 13.782L23.996 14.512L23.986 15.426L23.979 15.859L23.968 16.277L23.954 16.68L23.938 17.068L23.917 17.442L23.892 17.802L23.862 18.147L23.829 18.48C23.633 20.22 23.213 21.413 22.313 22.313C21.413 23.213 20.221 23.633 18.48 23.829L18.147 23.863L17.801 23.892L17.441 23.917L17.068 23.937L16.48 23.962L16.07 23.974L15.426 23.987L14.511 23.996L13.531 24L10.218 23.999L9.488 23.996L8.574 23.986L8.141 23.979L7.723 23.968L7.32 23.954L6.932 23.938L6.558 23.917L6.198 23.892L5.853 23.862L5.52 23.829C3.78 23.633 2.587 23.213 1.687 22.313C0.787 21.413 0.367 20.221 0.171 18.48L0.137 18.147L0.108 17.801L0.083 17.441L0.063 17.068L0.038 16.48L0.026 16.07L0.013 15.426L0.004 14.511L0 13.531L0.001 10.218L0.004 9.48798L0.014 8.57398L0.021 8.14098L0.032 7.72298L0.046 7.31998L0.062 6.93198L0.083 6.55798L0.108 6.19798L0.138 5.85298L0.171 5.51998C0.367 3.77998 0.787 2.58698 1.687 1.68698C2.587 0.786977 3.779 0.366977 5.52 0.170977L5.853 0.136977L6.199 0.107977L6.559 0.0829766L6.932 0.0629766L7.52 0.0379766L7.93 0.0259766L8.574 0.0129766L9.489 0.00397656ZM6.79 7.29998H4.05C4.18 13.54 7.3 17.29 12.77 17.29H13.08V13.72C15.09 13.92 16.61 15.39 17.22 17.29H20.06C19.28 14.45 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.73998 19.46 7.29998H16.88C16.32 9.27998 14.66 11.08 13.08 11.25V7.29998H10.5V14.22C8.9 13.82 6.88 11.88 6.79 7.29998Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
            <li class="d-flex justify-space-between">
              <a target="_blank" href="https://www.miigaik.ru/sveden/common/">Сведения об образовательной организации</a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="#"></a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="#"></a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="/docs/informaciya-o-zaschite-platejnyh-dannyh-pokupatelya"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="md-visible">
        <div class="footer__menu d-block mt-28">
          <nav class="menu__item">
            <div class="d-flex align-center justify-space-between mt-30">
              <router-link to="/catalog">Каталог курсов</router-link>
            </div>
            <div class="d-flex align-center justify-space-between mt-30">
              <a href="/catalog"></a>
            </div>
            <div class="d-flex align-center justify-space-between mt-30">
              <router-link to="/about">О проекте</router-link>
            </div>
          </nav>
          <ul class="footer__menu__big pt-40 pb-10 mt-44">
            <li class="mb-30"><a href="/#faq">Вопросы и ответы</a></li>
            <li><a target="_blank" href="https://t.me/universariumchat">Чат поддержки</a></li>
            <li class="mb-30"><router-link active-class="active" to="/news">Новости</router-link></li>
          </ul>
          <ul class="footer__menu__small pt-40 pb-10">
            <li class="mb-30">
              <a href="/docs/politika-konfidencialnosti-obrabotki-personalnyh-dannyh">Политика конфиденциальности</a>
            </li>
          </ul>
          <ul class="footer__menu__small pt-40 pb-10">
            <li class="d-flex justify-space-around">
              <a target="_blank" href="https://vk.com/miigaik">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.489 0.00397656L10.218 0.000976562H13.782L14.512 0.00397656L15.426 0.0139766L15.859 0.0209766L16.277 0.0319766L16.68 0.0459766L17.068 0.0619766L17.442 0.0829766L17.802 0.107977L18.147 0.137977L18.48 0.170977C20.22 0.366977 21.413 0.786977 22.313 1.68698C23.213 2.58698 23.633 3.77898 23.829 5.51998L23.863 5.85298L23.892 6.19898L23.917 6.55898L23.937 6.93198L23.962 7.51998L23.974 7.92998L23.987 8.57398L23.996 9.48898L24 10.469L23.999 13.782L23.996 14.512L23.986 15.426L23.979 15.859L23.968 16.277L23.954 16.68L23.938 17.068L23.917 17.442L23.892 17.802L23.862 18.147L23.829 18.48C23.633 20.22 23.213 21.413 22.313 22.313C21.413 23.213 20.221 23.633 18.48 23.829L18.147 23.863L17.801 23.892L17.441 23.917L17.068 23.937L16.48 23.962L16.07 23.974L15.426 23.987L14.511 23.996L13.531 24L10.218 23.999L9.488 23.996L8.574 23.986L8.141 23.979L7.723 23.968L7.32 23.954L6.932 23.938L6.558 23.917L6.198 23.892L5.853 23.862L5.52 23.829C3.78 23.633 2.587 23.213 1.687 22.313C0.787 21.413 0.367 20.221 0.171 18.48L0.137 18.147L0.108 17.801L0.083 17.441L0.063 17.068L0.038 16.48L0.026 16.07L0.013 15.426L0.004 14.511L0 13.531L0.001 10.218L0.004 9.48798L0.014 8.57398L0.021 8.14098L0.032 7.72298L0.046 7.31998L0.062 6.93198L0.083 6.55798L0.108 6.19798L0.138 5.85298L0.171 5.51998C0.367 3.77998 0.787 2.58698 1.687 1.68698C2.587 0.786977 3.779 0.366977 5.52 0.170977L5.853 0.136977L6.199 0.107977L6.559 0.0829766L6.932 0.0629766L7.52 0.0379766L7.93 0.0259766L8.574 0.0129766L9.489 0.00397656ZM6.79 7.29998H4.05C4.18 13.54 7.3 17.29 12.77 17.29H13.08V13.72C15.09 13.92 16.61 15.39 17.22 17.29H20.06C19.28 14.45 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.73998 19.46 7.29998H16.88C16.32 9.27998 14.66 11.08 13.08 11.25V7.29998H10.5V14.22C8.9 13.82 6.88 11.88 6.79 7.29998Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-120 text-center md-hidden text-footer">
        © КТЖ, 2018-{{ (new Date).getFullYear() }}. Все права защищены.<br/>
      </div>
      <div class="mt-30 text-center md-visible text-footer">
        © КТЖ, 2018-{{ (new Date).getFullYear() }}. Все права защищены.<br/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss">
.footer {
  background: #ededed;
  color: #A5A5A5;
  padding: 39px 24px;
  margin: 0;
  .menu {
    display: flex!important;
    @media screen and (max-width: 768px) {
      &__item a {
        color: #1F1F1F;
      }
    }
  }
  &__menu {
    display: flex;
    padding: 52px 117px 0;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        color: #A5A5A5;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          text-decoration: none;
          color: #A5A5A5;
          &:hover {
            color: #1f1f1f;
          }
        }
      }
    }
    &__big, &__small {
      border-top: 2px solid #EBEEF5;
    }
    &__small {
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 39px 0;
  }
  .text-footer {
    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 135%;
    }
  }
}
</style>