<template>
  <div class="catalog">
    <category-list v-model="filters" />
    <course-list :filters="filters" :pagination="true" :courses="allCourses"/>
  </div>
</template>

<script>

import CategoryList from "../components/course/CategoryList.vue";
import CourseList from "../components/course/CourseList.vue";
import {eventBus} from "../main";
import {mapGetters} from "vuex";

export default {
  name: "CatalogPage",
  components: {CourseList, CategoryList},
  data() {
    return {
      filters: {
        categories: [],
        title: null
      }
    }
  },
  computed: mapGetters(['allCourses']),
  async mounted() {
    eventBus.$on('filters:clear', () => {
      this.filters = {
        categories: [],
        title: null
      }
    })
  }
}
</script>