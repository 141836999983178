<template>
  <div class="about-page">
    <kinesis-container event="scroll" class="container">
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-first">
          <img src="../assets/images/kinesis-first.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-second">
          <img src="../assets/images/kinesis-second.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-third">
          <img src="../assets/images/kinesis-third.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-fourth">
          <img src="../assets/images/kinesis-fourth.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-five">
          <img src="../assets/images/kinesis-five.svg" />
        </div>
      </kinesis-element>
      <div class="about-page__wrapper about-page__wrapper-partners">
        <h1 class="text-rothek font-104 mb-60">Новости</h1>
        <h2 class="text-rothek font-36 mb-30" v-for="(news, index) in news" :key="index">
          <a target="_blank" :href="news.link">
            {{ news.title }}
          </a>
        </h2>
      </div>
      <feedback fill="orange" />
    </kinesis-container>
  </div>
</template>

<script>
import Feedback from "../components/Feedback.vue";
export default {
  name: "PartnersPage",
  components: {Feedback},
  data() {
    return {
      news: [
        {
          title: 'Новые этапы сотрудничества с Узбекистаном',
          link: 'https://t.me/miigaik_news/411'
        },
        {
          title: 'КТЖ соединяет пространства',
          link: 'https://t.me/scienpolicy/29665'
        },
        {
          title: 'Развитие Сетевого университета СНГ по пространственным данным',
          link: 'https://www.miigaik.ru/about/news/4961/'
        },
        {
          title: 'Интенсив "Startup: Начинаем с нуля!"',
          link: 'https://miigaik.ru/international/cis-network-university/news/startup/'
        },
        {
          title: 'Визионерские лекции на СНН-2023. Итоги',
          link: 'https://t.me/rectorkamynina/185'
        },
        {
          title: 'Экспорт российского образования в области геоданных',
          link: 'https://t.me/scienpolicy/32095'
        },
        {
          title: 'Делегация университета КТЖ на XIV Международном конгрессе высшего образования «Университет – 2024» в Гаване',
          link: 'https://t.me/rectorkamynina/403'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>